// CodeComposer:preserve:file => Don't override manual changes
/** @format */

'use strict';

angular
	.module('emsApp')
	.controller('BookingDetailController', function(
		$scope,
		$rootScope,
		$stateParams,
		$state,
		entity,
		$translate,
		dialogs,
		BookingSnelstartFactory,
		BookingFactory
	) {
		$scope.booking = entity;
		$scope.objectName = 'Booking';
		$scope.bookingId = $stateParams.bookingId;

		if (angular.isUndefined($rootScope.bookingActiveTab)) {
			//set the default here
			$rootScope.bookingActiveTab = 0;
		}

		$scope.goToEditState = function(booking) {
			$state.go('booking.detail.edit({bookingId:booking.id, mappedBy:mappedBy, mappedById:mappedById})');
		};

		var unsubscribeRefreshTriggerHandler = $rootScope.$on('emsApp:bookingDetailsRefreshTrigger', function () {
			BookingFactory.get({ id: $stateParams.bookingId }).$promise.then(function (result) {
				$scope.booking = result;
			});
		});

		// [dmitro] Without this callbacks will keep piling causing more and more resources wasted
		$scope.$on('$destroy', function () {
			if (angular.isFunction(unsubscribeRefreshTriggerHandler)) {
				unsubscribeRefreshTriggerHandler();
			}
		});

		// by broadcasting this event from an edit dialog the model is refreshed
		$rootScope.$on('emsApp:bookingUpdate', function(event, result) {
			$scope.booking = result;
		});

		// additional actions added by custom/jhipster/controller/detail/additional-actions.ftl

		// billBooking copied from booking-subscription-details.controller.js
		$scope.billBooking = function(booking) {
			$('#billButtonIcon').addClass('glyphicon-euro-animate');

			var preSnelstartId = booking.snelstartVerkoopOrderId;

			BookingSnelstartFactory.billBooking(
				booking,
				function(result) {
					$scope.booking = result;

					var dlgMsg;
					if (preSnelstartId !== result.snelstartVerkoopOrderId) {
						dlgMsg = $translate.instant('global.messages.info.bookingBilledSuccesfully', { status: result.status });
					} else {
						dlgMsg = $translate.instant('global.messages.info.bookingAcceptedSuccesfully', { status: result.status });
					}
					dialogs.notify($translate.instant('booking.dialog.bill.title.success'), dlgMsg).result.then(function() {
						$('#billButtonIcon').removeClass('glyphicon-euro-animate');
					});
				},
				function(error) {
					console.error(error);
					var dlgMsg = $translate.instant(error.data.message, error.data.params);
					dialogs.error($translate.instant('booking.dialog.bill.title.failed'), dlgMsg).result.then(function() {
						$('#billButtonIcon').removeClass('glyphicon-euro-animate');
					});
				}
			);
		};
		// end additional actions
	});
