/** @format */

'use strict';

//The factory to crud the main object
angular.module('emsApp').factory('OrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/:id',
		{},
		{
			query: { method: 'GET', isArray: false },
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
			update: { method: 'PUT' },
		}
	);
});

//The factory to fetch a new object with defaults from server
angular.module('emsApp').factory('NewOrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/new',
		{},
		{
			get: {
				method: 'GET',
				transformResponse: function(data) {
					data = angular.fromJson(data);
					return data;
				},
			},
		}
	);
});

//Get all OrderLines without the use of paging
angular.module('emsApp').factory('NoPagingOrderLineFactory', function($resource) {
	return $resource(
		'api/orderLines/nopaging',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

//Get all possible OrderLineOrigins Enum values without the use of paging
angular.module('emsApp').factory('NoPagingOrderLineOriginFactory', function($resource) {
	return $resource(
		'api/orderLine/orderLineOrigins',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});

angular.module('emsApp').factory('FindAllOrderLinesByQueryParamsFactory', function($resource) {
	return $resource(
		'api/query/orderLines/',
		{},
		{
			query: { method: 'GET', isArray: true },
		}
	);
});
