/** @format */

'use strict';

angular.module('emsApp').config(function($stateProvider) {
	/**
	 * Add all translations for a 'OrderLine'
	 */
	var getTranslations = function($translate, $translatePartialLoader) {
		$translatePartialLoader.addPart('orderline'); // main translation loaded for orderline
		$translatePartialLoader.addPart('orderlineorigin'); // one to many reference from main entity
		$translatePartialLoader.addPart('booking'); // one to many reference from main entity
		$translatePartialLoader.addPart('bookingstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('confirmationemailstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('subscription'); // one to many reference from main entity
		$translatePartialLoader.addPart('subscriptiontype'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('subscriptionstatus'); // one to one or many to one reference from main entity
		$translatePartialLoader.addPart('article'); // one to many reference from main entity

		return $translate.refresh();
	};

	/**
	 * "private" function called by wrapper new and edit functions.
	 */
	var getModalState = function(url, isEditMode) {
		if (!angular.isDefined(url) || url === null) {
			url = '/new';
		}

		if (!angular.isDefined(isEditMode) || isEditMode === null) {
			// default mode is NEW, however this situation should and will be avoided by as this function should only be
			isEditMode = false;
		}

		var templateUrl;
		// The default assignment to templateUrl can be overridden in hook custom/jhipster/state/template_url/add_or_edit.ftl
		templateUrl = 'app/entities/orderline/orderline-dialog.html';

		return {
			/* new state for entity orderLine */
			/* to access: ui-ref="orderline.list.new" */
			/* url: hostname/orderline/new */
			url: url,
			data: {
				authorities: ['ROLE_USER'],
				isModal: true,
			},
			params: {
				mappedBy: null,
				mappedByType: null,
				mappedById: null,
				dataModel: null, // this data model is set from the new relation state OR from the save and new logic
				mappedByDisplayValue: null,
				dataModelType: null, // this data model type is the name of the modelobject, lowercase

				hiddenParam: 'YES', // hides params from url
			},
			onEnter: [
				'$stateParams',
				'$state',
				'$uibModal',
				'$rootScope',
				function($stateParams, $state, $uibModal, $rootScope) {
					var navigateBack = true;

					$uibModal
						.open({
							templateUrl: templateUrl,
							controller: 'OrderLineDialogController',
							size: 'lg',
							backdrop: 'static',
							resolve: {
								entity: [
									'OrderLineFactory',
									'NewOrderLineFactory',
									function(OrderLineFactory, NewOrderLineFactory) {
										// NOTE either this dialog was opened with the purpose of
										// - creating a new OrderLine
										// - resuming the creation of a new new OrderLine AFTER new instances were created of x..1 reference(s)

										// if dataModel is defined, we're coming back from a new created x..1 reference
										if ($rootScope.isDefinedAndNotNull($stateParams.dataModel)) {
											var model = $stateParams.dataModel;
											// reset 'remembered' values to prevent future misbehaviour
											$rootScope.previousStateParams.dataModel = null;
											$stateParams.dataModel = null;

											// instead of returning a new model, we return the updated model
											return model;
										} else {
											if (isEditMode) {
												// get the entity by its ID
												return OrderLineFactory.get({ id: $stateParams.orderLineId }).$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											} else {
												// get a new empty entity
												return NewOrderLineFactory.get().$promise.then(
													function(data) {
														return data;
													},
													function(error) {
														return error;
													}
												);
											}
										}
									},
								],
								translatePartialLoader: [
									'$translate',
									'$translatePartialLoader',
									function($translate, $translatePartialLoader) {
										return getTranslations($translate, $translatePartialLoader);
									},
								],
							},
						})
						.result.then(function() {
							// process success result, if necessary
						})
						.catch(function(closeReason) {
							if (closeReason === 'createNew') {
								// only navigate back in history if the close reason allows us to
								navigateBack = false;
							}
						})
						.finally(function() {
							if (navigateBack) {
								$rootScope.back();
							}
						});
				},
			],
		};
	};

	/**
	 * Body for orderLine new state
	 */
	function getNewState(url) {
		return getModalState(url, false);
	}

	/**
	 * Body for orderLine edit state
	 */
	function getEditState(url) {
		return getModalState(url, true);
	}

	$stateProvider.state('orderline', {
		/* abstract state for entity orderLine */
		/* NOTE this state cannot be accessed directly, it must be inherited by a nested state by postpending the state name with a new state */
		abstract: true,
		parent: 'entity',
		url: '/orderline',
		data: {
			authorities: ['ROLE_USER'],
			pageTitle: 'OrderLines',
			isModal: false,
		},
		resolve: {
			translatePartialLoader: [
				'$translate',
				'$translatePartialLoader',
				function($translate, $translatePartialLoader) {
					return getTranslations($translate, $translatePartialLoader);
				},
			],
		},
	});

	$stateProvider.state('orderline.list', {
		/* list state for entity orderLine */
		/* to access: ui-ref="orderline.list" */
		/* url: hostname/orderline/list */
		url: '/list',
		views: {
			'content@': {
				templateUrl: 'app/entities/orderline/orderlines.html',
				controller: 'OrderLineController',
			},
		},
	});

	$stateProvider.state('orderline.detail', {
		/* detail state for entity orderLine */
		/* to access: ui-sref="orderline.detail" */
		/* url: hostname/orderline/detail/:orderLineId */
		url: '/detail/:orderLineId',
		views: {
			'content@': {
				templateUrl: 'app/entities/orderline/orderline-detail.html',
				controller: 'OrderLineDetailController',
			},
		},
		resolve: {
			entity: [
				'$stateParams',
				'OrderLineFactory',
				function($stateParams, OrderLineFactory) {
					return OrderLineFactory.get({ id: $stateParams.orderLineId });
				},
			],
		},
	});

	/* url: http://<hostname>/orderline/list/new */
	$stateProvider.state('orderline.list.new', getNewState());
	/* url: http://<hostname>/orderline/list/:orderLineId/edit */
	$stateProvider.state('orderline.list.edit', getEditState('/:orderLineId/edit'));
	/* url: http://<hostname>/orderline/detail/:orderLineId/edit */
	$stateProvider.state('orderline.detail.edit', getEditState('/edit'));
	// Article.orderLine with multiplicity 0..n points to OrderLine
	/* url: http://<hostname>/article/detail/:articleId/orderline/new */
	$stateProvider.state('article.detail.newOrderLine', getNewState('/orderline/new'));
	/* url: http://<hostname>/article/detail/:articleId/orderline/:orderLineId/edit */
	$stateProvider.state('article.detail.editOrderLine', getEditState('/orderline/:orderLineId/edit'));
	// Rare case of mixing non-dto's on a dto screen
	/* url: http://<hostname>/user-article/detail/:articleId/orderline/new */
	$stateProvider.state('user-article.detail.newOrderLine', getNewState('/orderline/new'));
	/* url: http://<hostname>/user-article/detail/:articleId/orderline/:orderLineId/edit */
	$stateProvider.state('user-article.detail.editOrderLine', getEditState('/orderline/:orderLineId/edit'));
	// Subscription.orderLine with multiplicity 0..n points to OrderLine
	/* url: http://<hostname>/subscription/detail/:subscriptionId/orderline/new */
	$stateProvider.state('subscription.detail.newOrderLine', getNewState('/orderline/new'));
	/* url: http://<hostname>/subscription/detail/:subscriptionId/orderline/:orderLineId/edit */
	$stateProvider.state('subscription.detail.editOrderLine', getEditState('/orderline/:orderLineId/edit'));
	// Booking.orderLine with multiplicity 0..n points to OrderLine
	/* url: http://<hostname>/booking/detail/:bookingId/orderline/new */
	$stateProvider.state('booking.detail.newOrderLine', getNewState('/orderline/new'));
	/* url: http://<hostname>/booking/detail/:bookingId/orderline/:orderLineId/edit */
	$stateProvider.state('booking.detail.editOrderLine', getEditState('/orderline/:orderLineId/edit'));
});
