/** @format */
// CodeComposer:preserve:file => Don't override manual changes

'use strict';

angular
	.module('emsApp')
	.controller('UserRelationBulkEditDtoController', function(
		$scope,
		$rootScope,
		$timeout,
		$state,
		$stateParams,
		$parse,
		_,
		entity,
		UserRelationBulkEditDtoFactory,
		AlertService
	) {
		$scope.selectedRelationIds = _.map($stateParams.selectedRelationList, 'id');
		$scope.selectedItemsAmount = $scope.selectedRelationIds.length;
		$scope.relation = entity;
		$scope.submitButtonIsActive = true;

		var onSaveFinishedCallback = function(result) {
			$scope.$emit('emsApp:relationUpdate', result);
			$scope.submitButtonIsActive = true;
			// process success result, if necessary
			if ($rootScope.isDefinedAndNotNull($stateParams.referredBy)) {
				console.log('as we came on this create new page from plus button we will update history');

				// set relation as reference in the referredBy data model
				$stateParams.referredBy.data[$stateParams.referredBy.refName] = result;

				// update previous state
				HistoryService.updateHistoryParamsData($stateParams.referredBy.data, $stateParams.referredBy.refName);
			}
			$rootScope.back();
		};

		var saveOrUpdateErrorCallback = function(error) {
			AlertService.error(error.data);
			$scope.updatingItemsInProgress = false;
		};

		$scope.save = function() {
			$scope.updatingItemsInProgress = true;
			UserRelationBulkEditDtoFactory.update(
				{ relationIds: $scope.selectedRelationIds },
				$scope.relation,
				onSaveFinishedCallback,
				saveOrUpdateErrorCallback
			);
		};

		// called from cancel button in dialog
		$scope.clear = function() {
			$rootScope.back();
		};

		// Custom validation of the summarize length of the first-last name fields
		$scope.$watchGroup(['relation.firstName', 'relation.lastName'], function (firstName, lastName) {
			var summOfLengths = ($scope.relation.firstName || '').length + ($scope.relation.lastName || '').length;
			$scope._validationSumOfLengths = summOfLengths
			var isLengthValid = summOfLengths <= 49;
			$scope.editForm.firstName.$setValidity('firstAndLastNameLengthSumm', isLengthValid);
			$scope.editForm.lastName.$setValidity('firstAndLastNameLengthSumm', isLengthValid);
		});
	});
