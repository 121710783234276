/** @format */

'use strict';
// Generated by jhipster/controller/genericOverviewWithPaging.ftl

angular
	.module('emsApp')
	.controller('OrderLineController', function(
		$scope,
		$rootScope,
		$state,
		$stateParams,
		$q,
		AlertService,
		OrderLineFactory,
		FindAllOrderLinesByQueryParamsFactory,
		AutocompleteBookingsByQueryParamsFactory,
		NoPagingOrderLineFactory,
		NoPagingBookingFactory,
		NoPagingSubscriptionFactory,
		NoPagingArticleFactory,
		NoPagingOrderLineOriginFactory
	) {
		$scope.orderLineList = [];
		$scope.showSearchForm = true;
		// a custom hook could be implemented here to add additional fields: custom/jhipster/controller/overview/additional-fields.ftl

		if ($rootScope.isDefinedAndNotNull($rootScope.pageSizeOrderLine)) {
			$scope.pageSize = angular.copy($rootScope.pageSizeOrderLine);
		} else {
			$scope.pageSize = 20;
			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);
		}

		$scope.goToNewState = function() {
			// this overview is NOT mapped, it is the main overview
			$state.go('orderline.list.new');
		};

		$scope.goToEditState = function(orderLine) {
			// this overview is NOT mapped, it is the main overview
			$state.go('orderline.list.edit', { orderLineId: orderLine.id });
		};

		var getBookingId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineSearchOverview.booking)) {
				return $scope.OrderLineSearchOverview.booking.id;
			} else {
				return undefined;
			}
		};

		var getSubscriptionIdList = function() {
			return $rootScope.getListValues($scope.OrderLineSearchOverview.subscriptionIdList, 'id');
		};

		var getArticleId = function() {
			if ($rootScope.isDefinedAndNotNull($scope.OrderLineSearchOverview.article)) {
				return $scope.OrderLineSearchOverview.article.id;
			} else {
				return undefined;
			}
		};

		$scope.autocompleteBookings = function(val) {
			var params = {
				bookingTypeAheadSearchString: val,
			};

			return AutocompleteBookingsByQueryParamsFactory.query(params).$promise.then(function(results) {
				return results;
			});
		};

		/**
		 * This function queries all entities of type Subscription and checks if this OrderLine dialog is called in the context of Subscription.
		 * If this is the case, we detect the parent entity OrderLine and select it as model value so it is shown in the dropdown select.
		 */
		$scope.querySubscription = function() {
			return NoPagingSubscriptionFactory.query(function(result) {
				return result;
			});
		};
		$scope.subscriptionReferenceFilterList = $scope.querySubscription();

		/**
		 * This function queries all entities of type Article and checks if this OrderLine dialog is called in the context of Article.
		 * If this is the case, we detect the parent entity OrderLine and select it as model value so it is shown in the dropdown select.
		 */
		$scope.queryArticle = function() {
			return NoPagingArticleFactory.query(function(result) {
				return result;
			});
		};
		$scope.articleReferenceFilterList = $scope.queryArticle();
		$scope.search = function() {
			$('#search-button').addClass('glyphicon-refresh-animate');

			$rootScope.OrderLineSearchOverview = angular.copy($scope.OrderLineSearchOverview);

			// A custom hook for setting page size can be implemented here: custom/jhipster/controller/overview/overview_uses_paging.ftl
			$rootScope.pageSizeOrderLine = angular.copy($scope.pageSize);

			FindAllOrderLinesByQueryParamsFactory.query(
				{
					page: $scope.OrderLineSearchOverview.page - 1,
					size: $scope.pageSize,
					bookingId: getBookingId(),
					subscriptionIdList: getSubscriptionIdList(),
					articleId: getArticleId(),
					origin: $scope.OrderLineSearchOverview.origin,

					sort: [$scope.OrderLineSearchOverview.predicate + ',' + ($scope.OrderLineSearchOverview.ascending ? 'asc' : 'desc')],
				},
				function(result, headers) {
					$scope.totalItems = headers('X-Total-Count');
					$scope.totalCountIsKnownAtTheMoment = true;
					$scope.orderLineList = result;
					$('#search-button').removeClass('glyphicon-refresh-animate');
				}
			);
		};

		$scope.updateSubscriptionUISelect = function(item, model) {
			$scope.search();
		};
		$scope.isOrderLineSearchOverviewDirty = function() {
			return !angular.equals($scope.OrderLineSearchOverview, $scope.originalOrderLineSearchOverview);
		};

		$scope.loadDefaultSearchParams = function() {
			$rootScope.OrderLineSearchOverview = {};
			$rootScope.OrderLineSearchOverview.page = 1;
			$rootScope.OrderLineSearchOverview.predicate = 'id';
			$rootScope.OrderLineSearchOverview.ascending = false;

			$scope.OrderLineSearchOverview = angular.copy($rootScope.OrderLineSearchOverview);

			$scope.originalOrderLineSearchOverview = angular.copy($scope.OrderLineSearchOverview);
		};

		$scope.reset = function() {
			$scope.loadDefaultSearchParams();
			if ($rootScope.isDefinedAndNotNull($scope.searchForm)) {
				// we check the availability of search form as it is still used in search forms that are not inlined in the table
				$scope.searchForm.$setPristine();
			}
			$scope.search();
		};

		$scope.loadPage = function() {
			if (angular.isUndefined($rootScope.OrderLineSearchOverview) || $rootScope.OrderLineSearchOverview === null) {
				$scope.loadDefaultSearchParams(); // load defaults
			}

			$scope.OrderLineSearchOverview = angular.copy($rootScope.OrderLineSearchOverview);
			$scope.search();
		};

		$scope.singleDelete = function(id) {
			$scope.selectedOrderLineId = id;
			$('#deleteOrderLineConfirmation').modal('show');
		};

		$scope.confirmSingleDelete = function() {
			OrderLineFactory.delete(
				{ id: $scope.selectedOrderLineId },
				function() {
					$scope.search();
					$('#deleteOrderLineConfirmation').modal('hide');
				},
				function(error) {
					$('#deleteOrderLineConfirmation').modal('hide');
					AlertService.error(error.data.message, error.data.params);
				}
			);
		};

		$scope.loadPage();

		$scope.getSortIcon = function(itemName) {
			if ($scope.OrderLineSearchOverview.predicate === itemName) {
				if ($scope.OrderLineSearchOverview.ascending) {
					return 'glyphicon-sort-by-attributes';
				} else {
					return 'glyphicon-sort-by-attributes-alt';
				}
			} else {
				return 'glyphicon-sort';
			}
		};
	});
